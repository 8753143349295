var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "svg",
    {
      attrs: {
        width: "18",
        height: "19",
        viewBox: "0 0 18 19",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M16.4348 6.04207V5.20548C16.4348 4.62916 16.2205 4.07143 15.8385 3.64384C15.4519 3.21624 14.9255 2.94203 14.3478 2.87696V2.34247C14.3478 1.71967 14.1009 1.12476 13.6584 0.687867C13.2205 0.246331 12.6242 0 12 0H6C5.37578 0 4.7795 0.246331 4.34161 0.687867C3.89907 1.12476 3.65217 1.71967 3.65217 2.34247V2.87696C3.07919 2.94203 2.54814 3.2116 2.16149 3.64384C1.77484 4.07143 1.56522 4.62916 1.56522 5.20548V6.04207C1.09472 6.28376 0.698758 6.64628 0.423913 7.09711C0.14441 7.5433 0 8.06385 0 8.58904V16.137C0 16.8946 0.302795 17.6243 0.838509 18.1634C1.37888 18.7025 2.10559 19 2.86957 19H15.1304C15.8898 19 16.6211 18.6979 17.1615 18.1634C17.7019 17.6243 18 16.8992 18 16.137V8.58904C18 8.06385 17.8556 7.5433 17.5761 7.09711C17.3012 6.64628 16.9053 6.27911 16.4348 6.04207ZM16.4348 16.137C16.4348 16.4809 16.295 16.8109 16.0528 17.0572C15.8106 17.3036 15.4752 17.4384 15.1304 17.4384H2.86957C2.52484 17.4384 2.1941 17.3036 1.9472 17.0572C1.70497 16.8156 1.56522 16.4809 1.56522 16.137V8.58904C1.56522 8.24511 1.70497 7.91512 1.9472 7.66879C2.18944 7.42246 2.52484 7.28767 2.86957 7.28767H15.1304C15.4752 7.28767 15.8059 7.4271 16.0528 7.66879C16.2997 7.91047 16.4348 8.24511 16.4348 8.58904V16.137Z",
          fill: _vm.isActive ? "#343A60" : "#FDFDFD",
        },
      }),
      _c("path", {
        attrs: {
          d: "M3.91298 4.42468H14.0869C14.2965 4.42468 14.4922 4.50834 14.6412 4.65242C14.7903 4.80115 14.8695 4.99636 14.8695 5.2055V5.72605H3.13037V5.2055C3.13037 4.99636 3.21422 4.80115 3.35863 4.65242C3.5077 4.50834 3.70335 4.42468 3.91298 4.42468Z",
          fill: "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M5.99989 1.56165H11.9999C12.2095 1.56165 12.4052 1.64531 12.5542 1.78939C12.6987 1.93347 12.7825 2.13332 12.7825 2.34247V2.86302H5.21729V2.34247C5.21729 2.13332 5.30114 1.93811 5.44555 1.78939C5.59461 1.64531 5.79027 1.56165 5.99989 1.56165Z",
          fill: "#717DC6",
        },
      }),
      _c("path", {
        attrs: {
          d: "M11.4783 10.411H6.52175C6.24225 10.411 5.98138 10.5597 5.84163 10.8014C5.70188 11.0431 5.70188 11.3406 5.84163 11.5822C5.98138 11.8239 6.23759 11.9727 6.52175 11.9727H11.4783C11.7578 11.9727 12.0186 11.8239 12.1584 11.5822C12.2982 11.3406 12.2982 11.0431 12.1584 10.8014C12.0186 10.5597 11.7578 10.411 11.4783 10.411Z",
          fill: "#717DC6",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }